import React, { useState } from "react";

import Textarea from "../../../components/form-item/Textarea";
// import Tab from "../../../components/tab/Tab";
import TitleBlock from "../../../components/block/TitleBlock";
import LanguageSwitcher from "../../../components/tab/LanguageSwitcher";
import Toggle from "../../../components/form-item/Toggle";
import Avatar from "../../../components/avatar/Avatar";
import Autofill from "../../../components/autofill/AutoFill";

const MySiteState = () => {
  const [language, setLanguage] = useState("JP");

  // トグル3種の個別の状態
  const [isAvatarVisible, setAvatarVisible] = useState(true);
  const [isArtistNameVisible, setArtistNameVisible] = useState(true);
  const [isTitleVisible, setTitleVisible] = useState(true);

  const [textareaValue, setTextareaValue] = useState("");

  // Autofillで選択された値を反映
  const handleSelectContent = (content) => {
    setTextareaValue(content);
  };

  // テキストエリアの変更を反映
  const handleTextareaChange = (event) => {
    setTextareaValue(event.target.value);
  };

  return (
    <div className="u-col u-gap16">
      <TitleBlock
        title={
          language === "JP" ? "アーティストステートメント" : "Artist Statement"
        }
      />
      <div className="u-col u-w100 u-gap16">
        <div className="u-col u-w100">
          {(isAvatarVisible ||
            isArtistNameVisible ||
            isTitleVisible ||
            textareaValue.trim() !== "") && (
            <div className="c-tag u-w100 u-col u-ptb16 u-pl16 u-pr16">
              <div className="u-row u-w100 u-gap16">
                {isAvatarVisible && (
                  <p>
                    <Avatar />
                  </p>
                )}
                <div className="bodytext-quote">{textareaValue}</div>
              </div>
              {(isArtistNameVisible || isTitleVisible) && (
                <div className="u-row-end u-w100">
                  <div className="c-quote-line"></div>
                  <div className="u-row u-gap16">
                    {isArtistNameVisible && (
                      <p>
                        {language === "JP" ? "アーティスト名" : "Artist Name"}
                      </p>
                    )}
                    {isTitleVisible && (
                      <p className="bodytext-13">
                        {language === "JP" ? "タイトル" : "Title"}
                      </p>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <Autofill
          group="statement"
          nowrap={true}
          onSelectContent={handleSelectContent}
        />
        <Textarea
          value={textareaValue}
          onChange={handleTextareaChange}
          rows="6"
          placeholder={
            language === "JP"
              ? "私たちは、あらゆるシーンに寄り添い、そこでしか味わえない音を創造します。"
              : "We are close to every scene and create sounds that can only be experienced there."
          }
        ></Textarea>

        {/* <LanguageSwitcher onLanguageChange={(lang) => setLanguage(lang)} /> */}

        <div className="u-row u-w100 u-gap24">
          <div onClick={() => setAvatarVisible((prev) => !prev)}>
            <Toggle
              value={isAvatarVisible}
              children={language === "JP" ? "アバター" : "Avatar"}
            />
          </div>
          <div onClick={() => setArtistNameVisible((prev) => !prev)}>
            <Toggle
              value={isArtistNameVisible}
              children={language === "JP" ? "アーティスト名" : "Artist Name"}
            />
          </div>
          <div onClick={() => setTitleVisible((prev) => !prev)}>
            <Toggle
              value={isTitleVisible}
              children={language === "JP" ? "タイトル" : "Title"}
            />
          </div>
        </div>
      </div>

      <LanguageSwitcher onLanguageChange={(lang) => setLanguage(lang)} />
    </div>
  );
};

export default MySiteState;
