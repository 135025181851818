import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import { useLoginUserMutation } from "../../../app/accounts/authuser";
import "./index.scss";
import { randomImageBackground, removeNavbar, resizeAuth } from "./random";

const ItemSocialLogin = styled.a`
  display: flex;
  flex-direction: column;
  width: fit-content;
  p {
    width: 50px;
    font-size: 10px;
    word-break: break-word;
  }
`;

const Div = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
`;

const LoginLayout = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [loginUser, { isLoading }] = useLoginUserMutation();
  const navigate = useNavigate();

  // Handle login form submission
  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      try {
        const result = await loginUser({ username, password, rememberMe });
        if (result.data?.token) {
          navigate("/top");
        }
      } catch (err) {
        // エラーハンドリングのみ残す
      }
    },
    [loginUser, navigate, username, password, rememberMe],
  );

  // Set up background images and event listeners
  useEffect(() => {
    const images = ["img1", "img2", "img3", "img4", "img5", "img6", "img7"];
    removeNavbar();
    randomImageBackground(images);
    resizeAuth();

    const handleResize = () => resizeAuth();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="login">
      <div className="banner">
        <div className="bxslider">
          <div className="banner__img visible-xs visible-sm img0" />
          <div className="banner__img-pc img0" />
        </div>
      </div>
      <div className="auth">
        <div className="auth__content">
          <div className="auth__main">
            <div className="auth__form">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <input
                    type="text"
                    name="username"
                    maxLength="50"
                    className="input-box input-user-name-signin"
                    placeholder="メールアドレス"
                    id="id_username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    name="password"
                    maxLength="20"
                    minLength="8"
                    className="input-box input-password-signin"
                    placeholder="パスワード"
                    id="id_password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <div className="checkbox">
                    <input
                      type="checkbox"
                      name="remember_me"
                      id="id_remember_me"
                      checked={rememberMe}
                      onChange={(e) => setRememberMe(e.target.checked)}
                    />
                    <label htmlFor="id_remember_me">
                      サインインしたままにする
                    </label>
                  </div>
                </div>
                <div className="form-group">
                  <div className="auth__form-forgotpass">
                    パスワードを忘れた場合は
                    <a href="/accounts/password/reset/">こちら</a>
                  </div>
                </div>
                <div className="form-group text-center" style={{ padding: 0 }}>
                  <button
                    type="submit"
                    className="btn button button--submit auth__form-button btn-signin-form"
                    disabled={isLoading || !username || !password}
                    style={{ fontSize: 16 }}
                  >
                    サインイン
                  </button>
                </div>
                <div className="form-group join-form__social-title text-center">
                  OR
                </div>
                <div
                  className="login-social form-group"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <Div className="join-form__social-list">
                    <ItemSocialLogin
                      className="item-login"
                      title="Googleでログイン"
                      href="/social/login/google-oauth2/?next=/accounts/handle_sns_signup_redirect"
                    >
                      <div className="join-form__social-network google" />
                      <p>Googleでログイン</p>
                    </ItemSocialLogin>
                    <ItemSocialLogin
                      className="item-login"
                      href="/social/login/facebook/?next=/accounts/handle_sns_signup_redirect"
                    >
                      <div className="join-form__social-network facebook" />
                      <p>Facebookでログイン</p>
                    </ItemSocialLogin>
                  </Div>
                </div>
              </form>
              <hr />
              <div className="form-group form-bottom">
                <div
                  className="auth__form-register"
                  style={{ textAlign: "center" }}
                >
                  アカウントをお持ちでない場合は
                  <a href="/accounts/signup">サインアップ</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginLayout;
