import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import scrollReducer from "./actions/layout/scroll";
import mileageReducer from "./mileages/mileagerank";
import { listProjectApi } from "./services/project";
import {
  profileApi,
  loginApi,
  authUserReducer,
  profileReducer,
} from "./accounts/authuser";

export const store = configureStore({
  reducer: {
    [profileApi.reducerPath]: profileApi.reducer,
    [loginApi.reducerPath]: loginApi.reducer,
    [listProjectApi.reducerPath]: listProjectApi.reducer,

    layout: scrollReducer,
    mileage: mileageReducer,

    profile: profileReducer,
    AuthUser: authUserReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      profileApi.middleware,
      loginApi.middleware,
      listProjectApi.middleware,
    ),
});

setupListeners(store.dispatch);
