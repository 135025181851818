import React, { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";
import InfiniteScroll from "react-infinite-scroll-component";

import { linkList } from "../../constant/links";
import { useGetProfileByNameQuery } from "../../app/accounts/authuser";
import { useGetProjectListQuery } from "../../app/services/project";
import ShareModal from "./components/modal/ShareModal";
import ProcessingSceneModal from "./components/modal/ProcessingSceneModal";
import ProductBanner from "./components/project-banner/index";
import SvgLoadingAnimation from "../../components/loading";

const TopPageLayout = () => {
  let [page, setPage] = useState(1);
  let [projects, setProjects] = useState([]);
  let [total, setTotal] = useState(0);
  let [allowDone, setAllowDone] = useState(false);
  let [orderBy, setOrderBy] = useState("modified");
  let [orderType, setOrderType] = useState(true);
  let [isFirst, setIsFirst] = useState(true);

  const { data } = useGetProfileByNameQuery("");
  let {
    data: projectData,
    isLoading: loading,
    isFetching,
  } = useGetProjectListQuery({
    page: page,
    projects_per_page: 10,
    project_status: allowDone ? "done" : "undone",
    order_by: orderBy,
    order_type: orderType ? "-" : "",
  });

  const [isShareModalOpen, setShareModalOpen] = useState(false);
  const [isProcessingSceneModalOpen, setProcessingSceneModalOpen] =
    useState(false);

  let user = data?.result;

  // Load data whenever projectData changes
  useEffect(() => {
    if (projectData) {
      setProjects((prevProjects) => [...prevProjects, ...projectData.projects]);
      setTotal(projectData?.total_page);
      setPage(projectData?.current_page);
    }
  }, [projectData]);

  useEffect(() => {
    setProjects([]);
    setPage(1);
  }, [allowDone, orderBy, orderType]);

  // Function to load the next page
  const loadMore = () => {
    if (!isFetching && !loading) {
      setPage(page + 1);
    }
  };

  return (
    <div>
      <main className={`owner-top ${isMobile ? "on-mobile" : ""}`}>
        <div className="container">
          <div className="sprojects">
            {["master_client", "admin"].includes(user?.role) ? (
              <div className="sprojects-add-project">
                <a
                  className="sprojects-add-project__content"
                  href={
                    user?.role === "master_client"
                      ? linkList.app.direct_create_offer
                      : linkList.app.artist_create_product
                  }
                >
                  <span
                    className="material-symbols-rounded"
                    style={{
                      fontSize: "32px",
                    }}
                  >
                    add_circle
                  </span>
                  <div className="sprojects-add-project__text bodytext-11">
                    {user.role === "master_client"
                      ? "プロジェクトを相談"
                      : "プロジェクトを作成"}
                  </div>
                </a>
              </div>
            ) : (
              <></>
            )}
            <div className="sprojects-filter">
              <div className="form-check custom-switch">
                <label className="form-check-label">
                  <div className="form-check-group">
                    <input
                      className="form-check-input switch-checkbox"
                      type="checkbox"
                      name="switch-chapter"
                      id="switch-chapter"
                      checked={allowDone} // Bind checkbox to state
                      onChange={() => {
                        setIsFirst(false);
                        setAllowDone(!allowDone);
                      }} // Update state on change
                    />
                    <span className="switch-slider"></span>
                  </div>
                  <span className="switch-label">アーカイブも観る</span>
                </label>
              </div>
              <div className="video-order">
                <div
                  className={`video-order-type video-order__asc ${orderType ? "" : "active"}`}
                  onClick={() => {
                    if (orderType) {
                      setIsFirst(false);
                      setOrderType(false);
                    }
                  }}
                >
                  <i className="icon icon--sicon-asc"></i>
                </div>
                <div
                  className={`video-order-type video-order__desc ${orderType ? "active" : ""}`}
                  onClick={() => {
                    if (!orderType) {
                      setIsFirst(false);
                      setOrderType(true);
                    }
                  }}
                >
                  <i className="icon icon--sicon-desc"></i>
                </div>
              </div>
              <div className="video-order-by">
                <div className="sselect-wrapper select-white">
                  <select
                    className="select"
                    id="video-orderby"
                    placeholder="Select Here"
                    data-search="false"
                    data-search-text="Enter the keyword"
                    value={orderBy}
                    onChange={(e) => {
                      setIsFirst(false);
                      setOrderBy(e.target.value);
                    }}
                  >
                    <option value="modified">最終更新日</option>
                    <option value="created">開始日</option>
                    <option value="rating">評価</option>
                    <option value="order">手動</option>
                  </select>
                </div>
              </div>
            </div>

            <div
              className="project-list"
              data-total-page={total}
              style={{ width: "100%" }}
            >
              {isFirst && page === 1 && (loading || isFetching) ? (
                <>
                  <div className="text-center">
                    <SvgLoadingAnimation />
                  </div>
                </>
              ) : (
                <></>
              )}
              <InfiniteScroll
                dataLength={projects.length} //This is important field to render the next data
                next={loadMore}
                hasMore={page < total}
                // below props only if you need pull down functionality
                // refreshFunction={this.refresh}
                // pullDownToRefresh
                // pullDownToRefreshThreshold={50}
                // pullDownToRefreshContent={
                //   <h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>
                // }
                // releaseToRefreshContent={
                //   <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
                // }
                loader={
                  <>
                    <div className="text-center">
                      <SvgLoadingAnimation />
                    </div>
                  </>
                }
              >
                {projects.map((project) => {
                  const hasNotification = false; //checkNotificationProduct(user, project); // Replace with actual logic to check notifications
                  const isPC = !isMobile; // Implement this function to check if the device is a PC
                  return (
                    <div
                      key={project.product_id}
                      className={`project-item-handle ${!hasNotification ? "no-notification" : ""}`}
                      data-product-user-id={project.product_id}
                    >
                      <a
                        href={
                          user.role === "master_admin"
                            ? `/project/message_owner/${project.product_id}` // Update this with the correct URL structure
                            : `/top/project/${project.product_id}` // Update this with the correct URL structure
                        }
                      >
                        <div
                          className={`project-item ${!isPC ? "on-mobile" : ""}`}
                          data-project-id={project.product_id}
                        >
                          <ProductBanner
                            project={project}
                            user={user}
                            typePage={"top_page"}
                            projectList={true}
                            isPC={isPC}
                            showStaff={true}
                          />
                        </div>
                      </a>
                    </div>
                  );
                })}
              </InfiniteScroll>
            </div>
            <div
              className="project-member-setting-modal modal fade"
              id="project-member-setting"
              role="dialog"
              style={{ display: "none" }}
            ></div>
            <ShareModal
              isOpen={isShareModalOpen}
              onClose={() => setShareModalOpen(false)}
            />
            <ProcessingSceneModal
              isOpen={isProcessingSceneModalOpen}
              onClose={() => setProcessingSceneModalOpen(false)}
            />
          </div>
        </div>
      </main>
    </div>
  );
};

export default TopPageLayout;
