import React, { useState } from "react";

import Input from "../../../components/form-item/Input";
import Textarea from "../../../components/form-item/Textarea";
import TitleBlock from "../../../components/block/TitleBlock";
import LanguageSwitcher from "../../../components/tab/LanguageSwitcher";
import ListBlock from "../../../components/block/ListBlock";
import Button from "../../../components/button/Button";
import Modal from "../../../components/modal/Modal";

const MySiteText = () => {
  const [language, setLanguage] = useState("JP");

  // 段落追加モーダル用
  const [modalAddProfileOpen, setModalAddProfileOpen] = useState(false);
  const [modalAddRecordOpen, setModalAddRecordOpen] = useState(false);
  const [modalAddBiographyOpen, setModalAddBiographyOpen] = useState(false);

  const handleAddProfileClick = () => {
    setModalAddProfileOpen(true);
  };
  const handleAddRecordClick = () => {
    setModalAddRecordOpen(true);
  };
  const handleAddBiographyClick = () => {
    setModalAddBiographyOpen(true);
  };

  // 編集モーダル用
  const [modalEditProfileOpen, setModalEditProfileOpen] = useState(false);
  const [modalEditRecordOpen, setModalEditRecordOpen] = useState(false);
  const [modalEditBiographyOpen, setModalEditBiographyOpen] = useState(false);

  const handleEditProfileClick = () => {
    setModalEditProfileOpen(true);
  };
  const handleEditRecordClick = () => {
    setModalEditRecordOpen(true);
  };
  const handleEditBiographyClick = () => {
    setModalEditBiographyOpen(true);
  };

  return (
    <div className="u-col u-gap24">
      <TitleBlock
        title="プロフィールテキスト"
        description="自分を伝える文章・実績・バイオグラフィ"
      />
      <div className="u-w100 u-col">
        <div className="u-w100 u-col u-gap8 c-group">
          <ListBlock
            type="profile"
            isSortable={true}
            isDeletable={true}
            onClick={handleEditProfileClick}
          />
          <ListBlock
            type="profile"
            isSortable={true}
            isDeletable={true}
            onClick={handleEditProfileClick}
          />
          <ListBlock type="add" label="追加" onClick={handleAddProfileClick} />
        </div>
      </div>

      <div className="u-w100 u-col">
        <p>実績</p>
        <div className="u-w100 u-col u-gap8 c-group">
          <ListBlock
            type="record"
            isSortable={true}
            isDeletable={true}
            onClick={handleEditRecordClick}
          />
          <ListBlock
            type="record"
            isSortable={true}
            isDeletable={true}
            onClick={handleEditRecordClick}
          />
          <ListBlock type="add" label="追加" onClick={handleAddRecordClick} />
        </div>
      </div>

      <div className="u-w100 u-col">
        <p>バイオグラフィ</p>
        <div className="u-w100 u-col u-gap8 c-group">
          <ListBlock
            type="biography"
            isSortable={true}
            isDeletable={true}
            onClick={handleEditBiographyClick}
          />
          <ListBlock
            type="biography"
            isSortable={true}
            isDeletable={true}
            onClick={handleEditBiographyClick}
          />
          <ListBlock
            type="add"
            label="追加"
            onClick={handleAddBiographyClick}
          />
        </div>
      </div>

      {modalAddProfileOpen && (
        <Modal onClose={() => setModalAddProfileOpen(false)}>
          <div className="u-col u-gap16 u-w100">
            <Input
              type="text"
              placeholder={
                language === "JP" ? "プロフィールタイトル" : "Profile Title"
              }
            />
            <Textarea
              rows="6"
              placeholder={
                language === "JP"
                  ? "私たちは、あらゆるシーンに寄り添い、そこでしか味わえない音を創造します。"
                  : "We are close to every scene and create sounds that can only be experienced there."
              }
            ></Textarea>
            <LanguageSwitcher onLanguageChange={(lang) => setLanguage(lang)} />
            <div className="u-row u-gap8 u-justify-end u-w100">
              <Button name="キャンセル" color="tertiary" size="small" />
              <Button name="追加" color="primary" size="small" />
            </div>
          </div>
        </Modal>
      )}

      {modalEditProfileOpen && (
        <Modal onClose={() => setModalEditProfileOpen(false)}>
          <div className="u-col u-gap16 u-w100">
            <Input
              type="text"
              placeholder={
                language === "JP" ? "プロフィールタイトル" : "Profile Title"
              }
            />
            <Textarea
              rows="6"
              placeholder={
                language === "JP"
                  ? "私たちは、あらゆるシーンに寄り添い、そこでしか味わえない音を創造します。"
                  : "We are close to every scene and create sounds that can only be experienced there."
              }
            ></Textarea>
            <LanguageSwitcher onLanguageChange={(lang) => setLanguage(lang)} />
            <div className="u-row u-gap8 u-justify-end u-w100">
              <Button name="キャンセル" color="tertiary" size="small" />
              <Button name="OK" color="primary" size="small" />
            </div>
          </div>
        </Modal>
      )}

      {modalAddRecordOpen && (
        <Modal onClose={() => setModalAddRecordOpen(false)}>
          <div className="u-col u-gap16 u-w100">
            <Input type="text" placeholder="00/00/00" />
            <Textarea
              rows="2"
              placeholder={language === "JP" ? "〇〇へ出演" : "Appears in ..."}
            ></Textarea>
            <LanguageSwitcher onLanguageChange={(lang) => setLanguage(lang)} />
            <div className="u-row u-gap8 u-justify-end u-w100">
              <Button name="キャンセル" color="tertiary" size="small" />
              <Button name="追加" color="primary" size="small" />
            </div>
          </div>
        </Modal>
      )}

      {modalEditRecordOpen && (
        <Modal onClose={() => setModalEditRecordOpen(false)}>
          <div className="u-col u-gap16 u-w100">
            <Input type="text" placeholder="00/00/00" />
            <Textarea
              rows="2"
              placeholder={language === "JP" ? "〇〇へ出演" : "Appears in ..."}
            ></Textarea>
            <LanguageSwitcher onLanguageChange={(lang) => setLanguage(lang)} />
            <div className="u-row u-gap8 u-justify-end u-w100">
              <Button name="キャンセル" color="tertiary" size="small" />
              <Button name="OK" color="primary" size="small" />
            </div>
          </div>
        </Modal>
      )}

      {modalAddBiographyOpen && (
        <Modal onClose={() => setModalAddBiographyOpen(false)}>
          <div className="u-col u-gap16 u-w100">
            <Input type="text" placeholder="00/00/00" />
            <Textarea
              rows="2"
              placeholder={
                language === "JP"
                  ? "〇〇で活動開始"
                  : "Started activities in ..."
              }
            ></Textarea>
            <LanguageSwitcher onLanguageChange={(lang) => setLanguage(lang)} />
            <div className="u-row u-gap8 u-justify-end u-w100">
              <Button name="キャンセル" color="tertiary" size="small" />
              <Button name="追加" color="primary" size="small" />
            </div>
          </div>
        </Modal>
      )}

      {modalEditBiographyOpen && (
        <Modal onClose={() => setModalEditBiographyOpen(false)}>
          <div className="u-col u-gap16 u-w100">
            <Input type="text" placeholder="00/00/00" />
            <Textarea
              rows="2"
              placeholder={
                language === "JP"
                  ? "〇〇で活動開始"
                  : "Started activities in ..."
              }
            ></Textarea>
            <LanguageSwitcher onLanguageChange={(lang) => setLanguage(lang)} />
            <div className="u-row u-gap8 u-justify-end u-w100">
              <Button name="キャンセル" color="tertiary" size="small" />
              <Button name="OK" color="primary" size="small" />
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default MySiteText;
