import React, { useEffect, useState, useCallback, useMemo } from "react";
import debounce from "lodash.debounce";

import Input from "../../../components/form-item/Input";
import Select from "../../../components/form-item/Select";
import TitleBlock from "../../../components/block/TitleBlock";

const BankAccount = () => {
  // ローカル状態管理
  const [localUserData, setLocalUserData] = useState({
    bank: "",
    bank_branch: "",
    bank_branch_number: "",
    account_type: "normal",
    account_number: "",
    account_name: "",
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [updateError, setUpdateError] = useState(null);
  const [errors, setErrors] = useState({});

  // 初期データ取得
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch("http://localhost:8000/api/auth-user/1/");
        if (!response.ok) {
          throw new Error("Failed to fetch user data");
        }
        const data = await response.json();
        const userData = data.result || {}; // resultの内容を取得
        setLocalUserData({
          bank: userData.bank || "",
          bank_branch: userData.bank_branch || "",
          bank_branch_number: userData.bank_branch_number || "",
          account_type: userData.account_type || "normal",
          account_number: userData.account_number || "",
          account_name: userData.account_name || "",
        });
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  // debounce関数のメモ化
  const debouncedUpdateUserData = useMemo(
    () =>
      debounce(async (name, value) => {
        try {
          setUpdateError(null);
          const response = await fetch(
            "http://localhost:8000/api/auth-user/1/",
            {
              method: "PATCH",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ [name]: value }),
            },
          );
          if (!response.ok) {
            throw new Error("Failed to update user data");
          }
        } catch (err) {
          setUpdateError("データの更新に失敗しました。再試行してください。");
        }
      }, 500),
    [],
  );

  // 入力データの更新と保存処理
  const updateUserData = useCallback(
    (name, value) => {
      debouncedUpdateUserData(name, value);
    },
    [debouncedUpdateUserData],
  );

  // 入力変更時の処理
  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedData = { ...localUserData, [name]: value };
    setLocalUserData(updatedData);
    setErrors(validateForm(updatedData));
    updateUserData(name, value);
  };

  // 入力値のバリデーション
  const validateForm = (data) => {
    const newErrors = {};
    if (data.bank_branch_number && !/^\d{3}$/.test(data.bank_branch_number)) {
      newErrors.bank_branch_number = "3桁の数字で入力してください。";
    }
    if (data.account_number && !/^\d{7}$|^\d{10}$/.test(data.account_number)) {
      newErrors.account_number = "7桁または10桁の数字で入力してください。";
    }
    return newErrors;
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <>
      <TitleBlock
        title="振込先銀行口座"
        description="残高を出金するための銀行口座を登録"
      />
      <div className="u-col u-gap24 u-w100">
        <Input
          label="銀行名"
          option="[任意]"
          type="text"
          placeholder="楽天"
          width="100%"
          append="銀行"
          name="bank"
          value={localUserData.bank}
          onChange={handleChange}
          error={errors.bank}
        />
        <div className="u-row u-gap16 u-w100">
          <Input
            label="支店"
            option="[任意]"
            type="text"
            placeholder="青海"
            append="支店"
            name="bank_branch"
            value={localUserData.bank_branch}
            onChange={handleChange}
            error={errors.bank_branch}
          />
          <Input
            label="支店番号"
            option="[任意]"
            type="text"
            placeholder="123"
            name="bank_branch_number"
            maxLength="3"
            inputMode="numeric"
            value={localUserData.bank_branch_number}
            onChange={handleChange}
            error={errors.bank_branch_number}
          />
        </div>
        <div className="u-row u-gap16 u-w100">
          <Select
            value={localUserData.account_type}
            label="口座種別"
            options={[
              { value: "normal", name: "普通" },
              { value: "temp", name: "当座" },
              { value: "reverse_tax", name: "納税準備預金" },
              { value: "saving", name: "貯蓄" },
              { value: "other", name: "その他" },
            ]}
            name="account_type"
            onChange={(e) =>
              handleChange({
                target: { name: "account_type", value: e.target.value },
              })
            }
          />
          <Input
            label="口座番号"
            option="[任意]"
            type="text"
            placeholder="1234567"
            name="account_number"
            inputMode="numeric"
            maxLength="10"
            value={localUserData.account_number}
            onChange={handleChange}
            error={errors.account_number}
          />
        </div>
        <Input
          label="口座名義（カナ）"
          option="[任意]"
          type="text"
          placeholder="カ）ソレモ"
          name="account_name"
          value={localUserData.account_name}
          onChange={handleChange}
        />
        {updateError && <div className="error-message">{updateError}</div>}
      </div>
    </>
  );
};

export default BankAccount;
