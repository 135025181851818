import React from "react";
import styled from "styled-components";

import MemberItem from "./MemberItem";

const BlockUsersInProject = styled.div`
  width: min(100vw - 16px - 46px, 1140px * 0.382);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  top: 2px;
  right: 46px;
  position: absolute;
  z-index: 1;
  margin-top: 8px;
  padding: 40px 12px 24px;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.84);
  backdrop-filter: blur(13px);
  -webkit-backdrop-filter: blur(13px);
  box-shadow: 2px 4px 8px 0px rgba(0, 0, 0, 0.05);
`;

const MemberList = ({
  owners,
  members,
  admins,
  editableAll,
  product,
  user,
}) => {
  return (
    <BlockUsersInProject className="member-manage-sortable manage-owner-member-invited">
      <div className="c-group u-bg-blue u-col u-gap8 u-w100">
        <div className="heading u-text-white">クライアント</div>
        <div
          className={`u-col u-gap8 u-w100 member-manage-sortable member-manage__group ${editableAll ? "can-edit-all" : ""}`}
        >
          {owners.map((owner) => (
            <MemberItem
              key={owner.id}
              member={owner}
              product={product}
              // editable={editable}
              user={user}
              // onlineUsers={onlineUsers}
              role="責任者"
              // editableAll={editableAll}
              isOwner
              // loginProducer={loginProducer}
              // loginMasteradmin={loginMasteradmin}
              // loginClient={loginClient}
            />
          ))}
          {members.map((member) => (
            <MemberItem
              key={member.id}
              member={member}
              product={product}
              user={user}
              role="責任者"
            />
          ))}
        </div>
        {[
          "master-admin",
          "admin",
          "producer",
          "master-client",
          "client",
        ].includes(user?.role) ? (
          <div
            className="c-btn-text-white u-row u-justify-end u-w100 u-gap8"
            data-bs-toggle="modal"
            data-bs-target="#modal-invite-member"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            クライアントを招待
            <span class="material-symbols-rounded">navigate_next</span>
          </div>
        ) : (
          <></>
        )}
      </div>
    </BlockUsersInProject>
  );
};

export default MemberList;
