import React from "react";

import { useGetProfileByNameQuery } from "../../app/accounts/authuser";
import UnauthorizedHeader from "./components/UnauthorizedHeader";
import AuthorizedHeader from "./components/AuthorizedHeader";
import "./style.scss";

const GlobalMenu = ({ isHideTopBar = false }) => {
  // Using a query hook automatically fetches data and returns query values
  const { data, isLoading } = useGetProfileByNameQuery("", {
    skip: isHideTopBar,
  });
  // Individual hooks are also accessible under the generated endpoints:

  if (isHideTopBar) {
    return <></>;
  }

  if (isLoading || !data?.result?.is_active) {
    return <UnauthorizedHeader />;
  } else {
    return <AuthorizedHeader user={data?.result} />;
  }
};

export default GlobalMenu;
