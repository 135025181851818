import React, { useState } from "react";

import Button from "../../../components/button/Button";
import RadioCard from "../../../components/form-item/RadioCard";
import TitleBlock from "../../../components/block/TitleBlock";

const MySitePublic = () => {
  const [checked, setChecked] = useState("project");

  const handleChecked = (id) => {
    setChecked(id);
  };

  return (
    <div className="u-col u-gap16">
      <TitleBlock
        title="マイサイトの公開範囲とURL"
        description="サイトの公開に関するカスタマイズ"
      />

      <div className="u-col u-w100">
        <p>固有のURLを設定し、QRコードを生成できます。</p>
        <div className="c-group u-w100">
          {/* <div className="u-row u-gap4">
            <div className="heading">URL</div>
            <span className="label8 u-text-light-gray">[任意]</span>
          </div> */}
          <div className="u-col u-gap8 u-w100">
            <div className="u-row u-w100">
              <div
                className="bodytext-11 u-col-center u-text-right u-text-light-gray u-bg-border"
                style={{
                  width: "auto",
                  height: "48px",
                  paddingLeft: "16px",
                  paddingRight: "4px",
                  borderRadius: "4px 0 0 4px",
                }}
              >
                https://soremo.jp/
              </div>
              <input
                type="text"
                id="profile_url"
                name="profile_url"
                defaultValue=""
                placeholder=""
                maxLength={60}
                className=""
                style={{ height: "48px", borderRadius: "0 4px 4px 0" }}
              />
            </div>
            <Button name="コピー" size="large" color="secondary" />
          </div>
        </div>
      </div>

      <div className="u-col u-w100">
        <p>公開範囲設定</p>
        <div className="u-col u-w100 u-gap16">
          <RadioCard
            name="mysite_public"
            id="open"
            width="128px"
            height="128px"
            showRadio
            checked={checked === "open"}
            showImage
            onClick={() => handleChecked("open")}
          >
            <label>だれでも見れる</label>
            <p>すべての訪問者にプロフィールが公開されます。</p>
          </RadioCard>
          <RadioCard
            name="mysite_public"
            id="members"
            width="128px"
            height="128px"
            showRadio
            checked={checked === "members"}
            showImage
            onClick={() => handleChecked("members")}
          >
            <label>ログインユーザーにだけ見れる</label>
            <p>ログインしているユーザーだけにプロフィールが公開されます。</p>
          </RadioCard>
          <RadioCard
            name="mysite_public"
            id="close"
            width="128px"
            height="128px"
            showRadio
            checked={checked === "close"}
            showImage
            onClick={() => handleChecked("close")}
          >
            <label>クローズ</label>
            <p>
              自分がフォローしているユーザーたけにプロフィールが公開されます。
            </p>
          </RadioCard>
        </div>
      </div>
    </div>
  );
};

export default MySitePublic;
