import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useSelector } from "react-redux"; // Import useSelector to access Redux state

import Avatar from "../../avatar/Avatar";
import { getListMenuFromUser } from "../common/utils";

const GlobalMenu = styled.div`
  height: 64px;
  position: fixed;
  transition: top 0.2s;
  top: ${(props) => (props.headerTop ? `${props.headerTop}px` : "0px")};
  z-index: 999;
  background: #fff;

  border-bottom: 1px solid #f0f0f0;

  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(13px);
  -webkit-backdrop-filter: blur(13px);
`;

const TextLink = styled.a`
  &:visited {
    color: ${(props) => (props.$isFocus ? "#009ace" : "inherit")};
  }
  &:hover {
    color: #009ace;
  }
  span {
    color: #a7a8a9;
    font-size: 20px;
    &:hover {
      color: #009ace;
    }
  }
`;

const DropDownList = styled.div`
  position: relative;
  .icon--sicon-dropdown {
    color: #a7a8a9;
    font-size: 16px;
    cursor: pointer;
  }
`;
const Menu = styled.div`
  position: absolute;
  background-color: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 6px;
  box-shadow: none;
  padding: 0px 8px;
  width: clamp(256px - 32px, 10vw, 320px - 32px);
  right: 0;
  top: 45px;
`;

const LiItem = styled.li`
  margin-top: ${(props) => (props.$isCollectionLink ? "5px" : "")};
`;

const AuthorizedHeader = ({ user }) => {
  const listMenu = getListMenuFromUser({ user });

  const params = useLocation();

  const [openMenu, setOpenMenu] = useState(false);

  // Access the scroll state from Redux - you don't have to trigger addEventListener everywhere anymore, only need in global Wraper
  const scrollState = useSelector((state) => {
    return state.layout;
  });

  // Set headerTop based on scroll state
  const headerTop = scrollState === "up" ? 0 : scrollState === "down" ? -64 : 0;
  const isFocusProjectTab = params.pathname === "/top";

  const renderMenuItems = (user) => {
    const createMenuItem = (key, href, label, props = {}) => (
      <a key={key} className="bdropdown-item" href={href} {...props}>
        {label}
      </a>
    );

    const menuItems = [];

    // Non-admin users
    if (user.role !== "admin") {
      menuItems.push(
        createMenuItem(
          "account-info",
          `/accounts/update/${user.id}/`,
          "アカウント情報",
        ),
        createMenuItem("settings", `/accounts/setting/${user.id}/`, "利用設定"),
      );

      if (user.role === "master_client") {
        menuItems.push(
          createMenuItem(
            "payment",
            `/accounts/payment_info/${user.id}/`,
            "クレジットカード情報を設定",
          ),
          createMenuItem(
            "owner-help",
            "https://soremo.notion.site/Owner-Help-Center-76d42490929b46909d60f688413ac9a1",
            "ヘルプセンター",
            { target: "_blank", rel: "noopener noreferrer" },
          ),
        );
      } else {
        if (user.role === "master_admin") {
          menuItems.push(
            createMenuItem(
              "inspection",
              "/payments/master_admins/info",
              "検収状況",
            ),
          );
        }
        menuItems.push(
          createMenuItem(
            "partner-help",
            "https://soremo.notion.site/Partner-Help-Center-a294ba282ad9438a968a3cb9e1ef7c77",
            "ヘルプセンター",
            { target: "_blank", rel: "noopener noreferrer" },
          ),
        );
      }
    } else if (user?.role === "admin") {
      // Admin users
      menuItems.push(
        createMenuItem(
          "creator-info",
          `/accounts/creator_account/${user?.id}/`,
          "アカウント情報",
        ),
        createMenuItem(
          "creator-settings",
          `/accounts/creator_setting/${user?.creator_id}/`,
          "利用設定",
        ),
        createMenuItem(
          "wallet-balance",
          "/payments/artists/info",
          "ウォレットの残高",
        ),
        createMenuItem(
          "card-settings",
          `/accounts/payment_info/${user?.id}/`,
          "クレジットカード情報を設定",
        ),
        createMenuItem(
          "help-center",
          "https://soremo.notion.site/Partner-Help-Center-a294ba282ad9438a968a3cb9e1ef7c77",
          "ヘルプセンター",
          { target: "_blank", rel: "noopener noreferrer" },
        ),
      );
    }

    // Common sign-out item
    menuItems.push(
      createMenuItem("logout", "/accounts/logout", "サインアウト", {
        className: `u-border-top bdropdown-item btn-logout ${user.role === "admin" ? "is-admin" : ""}`,
      }),
    );

    return menuItems;
  };

  return (
    <GlobalMenu className="u-w100 u-row-center" headerTop={headerTop}>
      <div className="u-wrapper u-row-end u-gap24">
        <ul className="u-row-end u-gap32">
          {listMenu.map((menuInfo, index) => (
            <LiItem
              key={menuInfo.link}
              $isCollectionLink={menuInfo.name === "Collection"}
            >
              <TextLink
                $isFocus={isFocusProjectTab && index === 0}
                href={menuInfo.link}
                dataShow={menuInfo.name}
              >
                {menuInfo.name === "Collection" ? (
                  <span className="icon icon--sicon-bookmark fa-bookmark icon-bookmark-navbar"></span>
                ) : (
                  menuInfo.label
                )}
              </TextLink>
            </LiItem>
          ))}
        </ul>
        <Link to="/my-page">
          <Avatar
            size={40}
            src={user.medium_avatar}
            isOnline={user.is_online}
          />
        </Link>
        <DropDownList>
          <i
            className="icon icon--sicon-dropdown"
            onClick={() => {
              setOpenMenu(!openMenu);
            }}
          ></i>
          {openMenu && <Menu>{renderMenuItems(user)}</Menu>}
        </DropDownList>
      </div>
    </GlobalMenu>
  );
};

export default AuthorizedHeader;
