import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { createSlice } from "@reduxjs/toolkit";

import {
  getAuthTokenFromCookies,
  setAuthTokenToCookies,
} from "../../common/utils";
import { HOSTNAME } from "../../constant/host";

/* ===============================
  baseQueryの定義
=============================== */
const rawBaseQuery = fetchBaseQuery({
  baseUrl: HOSTNAME,
  prepareHeaders: (headers) => {
    // クッキー等から取得したトークンがあれば Authorization ヘッダを付与
    const token = getAuthTokenFromCookies();
    if (token) {
      headers.set("Authorization", `Token ${token}`);
    }
    return headers;
  },
});

/**
 * ラップした baseQuery
 * 401エラー時に window.location.replace でリダイレクトしようとしている。
 * ただし、本来はフック利用不可なので注意
 */
const baseQueryWithRedirect = async (args, api, extraOptions) => {
  const result = await rawBaseQuery(args, api, extraOptions);

  const location = window.location.pathname;

  // 401 Unauthorized の場合
  if (result.error && result.error.status === 401) {
    if (location !== "/" && location !== "/accounts/login") {
      window.location.replace("/accounts/login");
    }
  }

  return result;
};

export const profileApi = createApi({
  reducerPath: "profileApi",
  baseQuery: baseQueryWithRedirect,
  endpoints: (builder) => ({
    getProfileByName: builder.query({
      // 元コードでは: return /api/get-profile;
      // しかし文字列リテラルでないとエラーになるため修正
      query: () => "/api/get-profile",
    }),
    getTestProfiles: builder.query({
      query: () => ({
        url: "/api/get-profile-test",
        method: "GET",
      }),
    }),
    getAuthUsers: builder.query({
      query: () => ({
        url: "/api/get-authuser-test",
        method: "GET",
      }),
    }),
    getAuthUserById: builder.query({
      query: (id) => ({
        url: `/api/get-authuser-test/${id}/`,
        method: "GET",
      }),
    }),
    createAuthUser: builder.mutation({
      query: (userData) => ({
        url: "/api/create-authuser",
        method: "POST",
        body: userData,
      }),
    }),
    updateAuthUser: builder.mutation({
      query: ({ id, userData }) => ({
        url: `/api/update-authuser/${id}/`,
        method: "PUT",
        body: userData,
      }),
    }),
    deleteAuthUser: builder.mutation({
      query: (id) => ({
        url: `/api/delete-authuser/${id}/`,
        method: "DELETE",
      }),
    }),
  }),
});

export const loginApi = createApi({
  reducerPath: "loginApi",
  baseQuery: fetchBaseQuery({
    baseUrl: HOSTNAME,
    credentials: "include",
    redirect: "manual",
  }),
  endpoints: (builder) => ({
    loginUser: builder.mutation({
      query: (credentials) => {
        return {
          // baseUrl に HOSTNAME が設定されているので相対パスに修正
          url: "/api/login",
          method: "POST",
          body: {
            username: credentials.username,
            password: credentials.password,
            remember_me: credentials.rememberMe,
          },
        };
      },
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const token = data.token;
          if (token) {
            setAuthTokenToCookies(token);
          }
        } catch (error) {
          // console.error("Login failed:", error);
        }
      },
    }),
  }),
});

// RTK Query で生成されたフックをまとめてエクスポート
export const {
  useGetProfileByNameQuery,
  useGetTestProfilesQuery,
  useGetAuthUsersQuery,
  useGetAuthUserByIdQuery,
  useCreateAuthUserMutation,
  useUpdateAuthUserMutation,
  useDeleteAuthUserMutation,
} = profileApi;
export const { useLoginUserMutation } = loginApi;

export const fetchAuthUserById = profileApi.endpoints.getAuthUserById.initiate;
export const updatePartialAuthUser =
  profileApi.endpoints.updateAuthUser.initiate;

const authUserInitialState = {
  users: [],
  user: null,
  loading: false,
  error: null,
};

const authUserSlice = createSlice({
  name: "authUsers",
  initialState: authUserInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch all auth users
      .addMatcher(profileApi.endpoints.getAuthUsers.matchPending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addMatcher(
        profileApi.endpoints.getAuthUsers.matchFulfilled,
        (state, action) => {
          state.loading = false;
          state.users = action.payload;
        },
      )
      .addMatcher(
        profileApi.endpoints.getAuthUsers.matchRejected,
        (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        },
      )

      // Fetch auth user by ID
      .addMatcher(
        profileApi.endpoints.getAuthUserById.matchPending,
        (state) => {
          state.loading = true;
          state.error = null;
        },
      )
      .addMatcher(
        profileApi.endpoints.getAuthUserById.matchFulfilled,
        (state, action) => {
          state.loading = false;
          state.user = action.payload;
        },
      )
      .addMatcher(
        profileApi.endpoints.getAuthUserById.matchRejected,
        (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        },
      )

      // Create auth user
      .addMatcher(profileApi.endpoints.createAuthUser.matchPending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addMatcher(
        profileApi.endpoints.createAuthUser.matchFulfilled,
        (state, action) => {
          state.loading = false;
          state.users.push(action.payload);
        },
      )
      .addMatcher(
        profileApi.endpoints.createAuthUser.matchRejected,
        (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        },
      )

      // Update auth user
      .addMatcher(profileApi.endpoints.updateAuthUser.matchPending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addMatcher(
        profileApi.endpoints.updateAuthUser.matchFulfilled,
        (state, action) => {
          state.loading = false;
          state.users = state.users.map((user) =>
            user.id === action.payload.id ? action.payload : user,
          );
        },
      )
      .addMatcher(
        profileApi.endpoints.updateAuthUser.matchRejected,
        (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        },
      )

      // Delete auth user
      .addMatcher(profileApi.endpoints.deleteAuthUser.matchPending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addMatcher(
        profileApi.endpoints.deleteAuthUser.matchFulfilled,
        (state, action) => {
          state.loading = false;
          state.users = state.users.filter(
            (user) => user.id !== action.payload,
          );
        },
      )
      .addMatcher(
        profileApi.endpoints.deleteAuthUser.matchRejected,
        (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        },
      );
  },
});

export const authUserReducer = authUserSlice.reducer;

const profileInitialState = {
  value: {}, // Profile data
  loading: false,
  error: null,
};

const profileSlice = createSlice({
  name: "profile",
  initialState: profileInitialState,
  reducers: {
    setProfile: (state, action) => {
      state.value = action.payload;
    },
    unsetProfile: (state) => {
      state.value = {};
    },
  },
  extraReducers: (builder) => {
    builder
      // Handling the states of `profileApi.getTestProfiles` query
      .addMatcher(
        profileApi.endpoints.getTestProfiles.matchPending,
        (state) => {
          state.loading = true;
          state.error = null;
        },
      )
      .addMatcher(
        profileApi.endpoints.getTestProfiles.matchFulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.value = payload?.result;
        },
      )
      .addMatcher(
        profileApi.endpoints.getTestProfiles.matchRejected,
        (state, { error }) => {
          state.loading = false;
          state.error = error.message || "Something went wrong";
        },
      );
  },
});

export const { setProfile, unsetProfile } = profileSlice.actions;
export const profileReducer = profileSlice.reducer;

export default authUserSlice.reducer;
