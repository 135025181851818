import React from "react";

import TopPageLayout from "./index";
import ProjectPageLayout from "./project";
import ProjectPageCommentLayout from "./project/project-comment";
import ProjectPageDMLayout from "./project/project-dm";

const routes = [
  {
    path: "/top",
    exact: true,
    component: <TopPageLayout />,
  },
  {
    path: "/:projectId", // Dynamic segment added here￥
    // path: "/top/project/:projectId", // Dynamic segment added here
    exact: true,
    hideGlobalWraper: false,
    component: <ProjectPageLayout />,
  },
  {
    // path: "/top/project/:projectId/project-comment", // Dynamic segment added here
    path: "/:projectId/talkroom", // Dynamic segment added here
    exact: true,
    // hideGlobalWraper: true,
    component: <ProjectPageCommentLayout />,
  },
  {
    // path: "/top/project/:projectId/dm/:offerId", // Dynamic segment added here
    path: "/:projectId/dm/:offerId", // Dynamic segment added here
    exact: true,
    // hideGlobalWraper: true,
    component: <ProjectPageDMLayout />,
  },
];

export default routes;
