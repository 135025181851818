import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const SidebarContainer = styled.div`
  transition:
    width 0.2s ease-out,
    left 0.2s ease-out;
  background-color: var(--sidebar-bg-color, #fff); /* Placeholder color */
  overflow-y: auto;
  position: absolute;
  top: 0px;
  left: clamp(-375px * 0.5 + 16px, -20%, -640px);
  transition: 0.2s ease-out;
  height: calc(100dvh - 75px);
  padding: 12px 0px 80px 0px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  border-right: 1px solid var(--soremo-border);
  z-index: 400;
  &.toggled {
    width: clamp(375px * 0.5, 20%, 640px);
    left: 0;
    transition: 0.2s ease-out;
  }
`;

const ContentLeftSide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

const ChaterList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 4px 0 4px 0;
  list-style: none;
  margin: 0;
`;

const ChapterItem = styled.li`
  color: #000;
  font-size: 13px;
  line-break: anywhere;
  width: 100%;
  padding: 4px 0px 4px 0px;
  display: flex;
  align-items: center;
  list-style: none;
  padding-left: 16px;
  &:hover {
    cursor: pointer;
    background-color: #f0f0f0;
  }

  ${({ isActive }) =>
    isActive &&
    `
    color: #009ace !important;
    span {
      color: #009ace !important;
    }
  `}
`;

const BlockSetting = styled.div`
  border-top: 1px solid #f0f0f0;
  width: 100%;
`;

const LeftSideBar = ({
  productScenes,
  project,
  user,
  titlePage,
  visible,
  setVisible,
  currentProductSceneId,
  setCurrentProductSceneId,
}) => {
  const renderProductScenes = () => {
    return productScenes.map((ps) => (
      <ChapterItem
        isActive={ps.id === currentProductSceneId}
        key={ps.id}
        draggable="true"
        // onDragStart={(event) => console.log(event)}
        // onDrop={(event) => console.log(event)}
        // onDragOver={(event) => console.log(event)}
        data-ps-id={ps.pk}
        onClick={() => setCurrentProductSceneId(ps.id)}
      >
        {ps.name}
      </ChapterItem>
    ));
  };

  const canAddChapter = user.role === "admin";

  const renderSettings = () => {
    // const isMasterAdmin =
    //   user.role === "master_admin" ||
    //   (user.product_user &&
    //     ["master_admin", "producer"].includes(user.product_user.position));

    const SETTING_MENUS = [
      {
        type: "modal",
        target: "modalInfoProject",
        className: "btn-modal-info-project",
        label: `${titlePage}について`,
      },
      {
        type: "function",
        // onClick: (e) => console.log(e), //can add function you want to trigger here
        className: "btn-modal-staff-credit button-show-staff",
        label: (
          <>
            <span className="material-symbols-rounded">contract</span>
            スタッフクレジット
          </>
        ),
      },
      {
        type: "function",
        // onClick: (e) => console.log(e), //can add function you want to trigger here
        //export-url={`/app/acr_export_csv/${project.pk}`} update-project-url={`/app/top_project_detail/${project.pk}`}
        label: (
          <>
            <span className="material-symbols-rounded">fingerprint</span>
            楽曲認識レポート
          </>
        ),
      },
      {
        type: "link",
        role: ["master_admin"],
        label: (
          <>
            <span className="material-symbols-rounded">settings</span>設定
          </>
        ),
        url: `/app/project_setting/${project.id}`,
      },
    ];

    return (
      <ChaterList className="list-setting">
        {SETTING_MENUS.map((menu, index) => {
          if ((menu?.role && menu?.role?.includes(user?.role)) || !menu?.role) {
            switch (menu.type) {
              case "modal":
                return (
                  <ChapterItem
                    key={index}
                    className={menu?.className}
                    data-bs-toggle="modal"
                    data-bs-target={`#${menu?.target}`}
                  >
                    {menu?.label}
                  </ChapterItem>
                );
              case "function":
                return (
                  <ChapterItem
                    className={menu?.className}
                    onClick={menu?.onClick}
                  >
                    {menu?.label}
                  </ChapterItem>
                );
              case "link":
                return (
                  <a href={menu?.url} className={menu?.className}>
                    <ChapterItem className={menu?.className}>
                      {menu?.label}
                    </ChapterItem>
                  </a>
                );
              default:
                return <></>;
            }
          } else {
            return <></>;
          }
        })}
      </ChaterList>
    );
  };

  return (
    <SidebarContainer
      id="left-sidebar"
      className={`${visible ? "toggled" : ""} boder-siderbar u-bg-background open-sidebar`}
    >
      {/* Sidebar Close Button */}
      <span
        id="left-sidebar-close"
        className="material-symbols-rounded"
        style={{ paddingLeft: "16px" }}
        onClick={(e) => setVisible(false)}
      >
        view_sidebar
      </span>

      <ContentLeftSide>
        <div className="block-chapter">
          <ChaterList>
            {/* Product Scenes List */}
            {renderProductScenes()}

            {/* Add Chapter for Admin */}
            {canAddChapter && (
              <div className="pd-add-chapter pd-add-chapter-new">
                <a
                  className="pd-add-chapter__content button-upload-video-scene-title"
                  href="#create-chapter"
                >
                  <div className="c-btn-text bodytext-13 u-row u-pl16 u-gap8">
                    チャプターを作成
                    <span className="material-symbols-rounded">
                      navigate_next
                    </span>
                  </div>
                </a>
              </div>
            )}
          </ChaterList>
        </div>

        <BlockSetting>
          {/* Project Settings List */}
          {renderSettings()}
        </BlockSetting>
      </ContentLeftSide>
    </SidebarContainer>
  );
};

LeftSideBar.propTypes = {
  productScenes: PropTypes.array.isRequired,
  project: PropTypes.object.isRequired,
  user: PropTypes.shape({
    role: PropTypes.string.isRequired,
    product_user: PropTypes.object,
  }).isRequired,
  titlePage: PropTypes.string.isRequired,
};

export default LeftSideBar;
