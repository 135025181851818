import React, { useState } from "react";
// import { createPortal } from "react-dom";

import Input from "../../../components/form-item/Input";
import FileUploader from "../../../components/file-uploader/FileUploader";
import TitleBlock from "../../../components/block/TitleBlock";
import ListBlock from "../../../components/block/ListBlock";
import Modal from "../../../components/modal/Modal";
import Button from "../../../components/button/Button";
// import MySiteWorksAdd from "./MySiteWorksAdd";

const MySiteWorksList = () => {
  const [modalAddWorksOpen, setModalAddWorksOpen] = useState(false);

  const handleAddWorksClick = () => {
    setModalAddWorksOpen(true);
  };

  return (
    <>
      <TitleBlock
        title="作品登録"
        description="これまでの作品を登録し、実績を伝えましょう。"
      />

      <div className="u-w100 u-col u-gap16">
        <div className="u-w100 u-col u-gap4">
          <ListBlock type="works" isSortable={true} isDeletable={true} />
          <ListBlock type="works" isSortable={true} isDeletable={true} />
          <ListBlock type="works" isSortable={true} isDeletable={true} />
          <ListBlock type="works" isSortable={true} isDeletable={true} />
          <ListBlock type="works" isSortable={true} isDeletable={true} />
          <ListBlock type="works" isSortable={true} isDeletable={true} />
        </div>
        <ListBlock
          type="add"
          label="作品を追加"
          onClick={handleAddWorksClick}
        />
      </div>
      {modalAddWorksOpen && (
        <Modal onClose={() => setModalAddWorksOpen(false)}>
          <div className="u-col u-gap16 u-w100 u-pt256">
            {/* <TitleBlock
              title="作品登録"
              description="これまでの作品を登録し、実績を伝えましょう。"
            /> */}
            {/* <MySiteWorksList /> */}

            <div className="u-col u-w100">
              <div className="u-row u-gap4">
                <div className="heading">アップロード</div>
                <span className="label8 u-text-light-gray">[任意]</span>
              </div>
              <FileUploader />
            </div>

            <div className="u-col u-w100">
              <Input
                label="作品名"
                option="[必須]"
                type="text"
                placeholder="a sing for you"
                width="100%"
                append=""
                name=""
              />
            </div>

            <div className="u-col u-w100">
              <Input
                label="制作年"
                option="[任意]"
                type="text"
                placeholder="2024"
                width="80px"
                append="年"
                name=""
              />
            </div>

            <div className="u-col u-w100">
              <p>
                複数アーティストで制作した作品を掲載する場合は、クレジットを登録しましょう。
              </p>
              <Input
                label="クレジット"
                option="[任意]"
                type="text"
                placeholder="Music and Arrangement by ... Lyrics by ..."
                width="100%"
                append=""
                name=""
              />
            </div>

            <div className="u-col u-w100">
              <p>
                テーマや狙い、それがどのように表現されているかを伝えましょう。
              </p>
              <Input
                label="解説"
                option="[任意]"
                type="text"
                placeholder="聴く人に寄り添えるようなメロディーを意識しました。"
                width="100%"
                append=""
                name=""
              />
            </div>

            <div className="u-col u-w100">
              <p>ワードの区切りは、#で設定してください。</p>
              <Input
                label="ハッシュタグ"
                option="[任意]"
                type="text"
                placeholder="#ピアノ"
                width="100%"
                append=""
                name=""
              />
            </div>

            <div className="u-col u-w100">
              <div className="heading">
                販売方針
                <span className="u-pl4 label8 u-text-blue u-ml8">[必須]</span>
              </div>

              <div className="u-w100 u-col u-gap8 c-radio">
                <div className="u-col u-100">
                  <div className="u-row u-100 u-gap8">
                    <input
                      type="radio"
                      name="sales-policy"
                      id="sold-as-is"
                      defaultChecked=""
                    />
                    <label htmlFor="sold-as-is" className="heading-13">
                      そのまま販売
                    </label>
                  </div>
                  <p>作品に手を加えず、買取（著作権譲渡）で販売します。</p>
                </div>

                <div className="u-col u-100">
                  <div className="u-row u-gap8">
                    <input
                      type="radio"
                      name="sales-policy"
                      id="semi-order"
                      defaultChecked=""
                    />
                    <label htmlFor="semi-order" className="heading-13">
                      セミオーダーで販売
                    </label>
                  </div>
                  <p>
                    作品の一部をカスタマイズして販売します。購入された後も、販売を続けることができます。
                  </p>
                  {/* label分の余白が表示されてしまっている↓ */}
                  <Input
                    type="text"
                    placeholder="歌詞を変えて注文"
                    width="100%"
                    append=""
                    name=""
                  />
                </div>

                <div className="u-col u-100">
                  <div className="u-row u-gap8">
                    <input
                      type="radio"
                      name="sales-policy"
                      id="user-rights-only"
                      defaultChecked=""
                    />
                    <label htmlFor="user-rights-only" className="heading-13">
                      利用権を販売
                    </label>
                  </div>
                  <p>
                    作品をそのまま、非独占で利用権販売します。購入された後も、販売を続けることができます。
                  </p>
                </div>

                <div className="u-col u-100">
                  <div className="u-row u-gap8">
                    <input
                      type="radio"
                      name="sales-policy"
                      id="not-for-sale"
                      defaultChecked="checked"
                    />
                    <label htmlFor="not-for-sale" className="heading-13">
                      販売しない
                    </label>
                  </div>
                  <p>サンプル視聴のみの取り扱いとします。</p>
                </div>
              </div>
            </div>

            <div className="u-col u-w100">
              <p>設定価格以下のオファーは届かないようにできます。</p>
              <Input
                label="開始価格"
                option="[任意]"
                type="text"
                placeholder="50,000"
                width="100%"
                append="円（税抜）"
                name=""
              />
            </div>

            <div className="u-col u-w100">
              <p>設定価格を提示すれば、すぐ売却できるようにします。</p>
              <Input
                label="終了価格"
                option="[任意]"
                type="text"
                placeholder="200,000"
                width="100%"
                append="円（税抜）"
                name=""
              />
            </div>

            <div className="u-col u-w100">
              <p>
                掲載開始期間と終了期間を設定し、オークションスタイルで販売しましょう。
              </p>
              <Input
                label="開始日時"
                option="[任意]"
                type="text"
                placeholder="2024/10/28 10:19"
                width="100%"
                append=""
                name=""
              />
              <Input
                label="終了日時"
                option="[任意]"
                type="text"
                placeholder="2024/11/28 10:19"
                width="100%"
                append=""
                name=""
              />
            </div>

            <div className="u-row u-gap8 u-w100">
              <Button
                name="キャンセル"
                onClick={() => setModalAddWorksOpen(false)}
                color="secondary"
                size="medium"
              />
              <Button
                name="追加"
                onClick={() => setModalAddWorksOpen(false)}
                color="primary"
                size="medium"
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default MySiteWorksList;
