import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import debounce from "lodash/debounce";
import axios from "axios";

import Input from "../../../components/form-item/Input";
import Tab from "../../../components/tab/Tab";
import TitleBlock from "../../../components/block/TitleBlock";
import Toggle from "../../../components/form-item/Toggle";

// styled-components
const StyledLink = styled.p`
  width: 100%;
  text-align: center;
  cursor: pointer;
  color: var(--soremo-blue);

  &:hover {
    color: var(--soremo-deep-blue);
  }
`;

const PersonalInformation = () => {
  // ローカル状態
  const [localUserData, setLocalUserData] = useState({
    fullname: "",
    dob: "",
    phone: "",
    invoice_register_number: "",
    post_number: "",
    province: "",
    city: "",
    mansion: "",
    only_address: true,
    post_number2: "",
    province2: "",
    city2: "",
    mansion2: "",
  });

  const [activeTab, setActiveTab] = useState("請求先"); // タブ管理
  const [errors, setErrors] = useState({}); // エラー管理
  const [updateError, setUpdateError] = useState(null); // 保存エラー管理
  const [isSaving, setIsSaving] = useState(false); // 保存中の状態管理
  const [loading, setLoading] = useState(true); // 初期データ取得中の状態
  const [error, setError] = useState(null); // データ取得エラー

  // 初期データ取得
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          "http://localhost:8000/api/auth-user/1/",
        );
        const userData = response.data.result || {};
        setLocalUserData((prev) => ({
          ...prev,
          fullname: userData.fullname || "",
          dob: userData.dob || "",
          phone: userData.phone || "",
          invoice_register_number: userData.invoice_register_number || "",
          post_number: userData.post_number || "",
          province: userData.province || "",
          city: userData.city || "",
          mansion: userData.mansion || "",
          only_address: userData.only_address ?? true,
          post_number2: userData.post_number2 || "",
          province2: userData.province2 || "",
          city2: userData.city2 || "",
          mansion2: userData.mansion2 || "",
        }));
      } catch (err) {
        setError("データ取得に失敗しました。");
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  // debounce関数のメモ化
  const debouncedUpdateUserData = useMemo(
    () =>
      debounce(async (name, value, autoAddress = false) => {
        try {
          setUpdateError(null);
          setIsSaving(true);

          // 部分更新を実行
          await axios.patch("http://localhost:8000/api/auth-user/1/", {
            [name]: value,
          });

          // 住所自動取得処理
          if (autoAddress) {
            const postalCode = value.replace("-", "");
            if (postalCode.length === 7) {
              const response = await axios.get(
                `https://zipcloud.ibsnet.co.jp/api/search?zipcode=${postalCode}`,
              );
              if (response.data.results) {
                const addressData = response.data.results[0];
                const addressFields =
                  name === "post_number"
                    ? {
                        province: addressData.address1,
                        city: `${addressData.address2}${addressData.address3}`,
                      }
                    : {
                        province2: addressData.address1,
                        city2: `${addressData.address2}${addressData.address3}`,
                      };

                setLocalUserData((prev) => ({ ...prev, ...addressFields }));
                // データベースへの反映
                await axios.patch(
                  "http://localhost:8000/api/auth-user/1/",
                  addressFields,
                );
              } else {
                throw new Error("該当する住所が見つかりませんでした。");
              }
            }
          }
        } catch (err) {
          setUpdateError(
            "データの保存または住所取得に失敗しました。再試行してください。",
          );
        } finally {
          setIsSaving(false);
        }
      }, 500),
    [],
  );

  // 入力変更時の処理
  const handleChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;

    if (name === "dob") {
      const date = new Date(newValue);
      newValue = new Date(date.getTime() - date.getTimezoneOffset() * 60000)
        .toISOString()
        .split("T")[0];
    }

    if (name === "post_number" || name === "post_number2") {
      newValue = newValue.replace(/\D/g, "");
      if (newValue.length > 7) newValue = newValue.slice(0, 7);
      if (newValue.length > 3) {
        newValue = `${newValue.slice(0, 3)}-${newValue.slice(3)}`;
      }
    }

    setLocalUserData((prev) => ({
      ...prev,
      [name]: newValue,
    }));

    setErrors(validateForm({ ...localUserData, [name]: newValue }));
    debouncedUpdateUserData(
      name,
      newValue,
      name === "post_number" || name === "post_number2",
    );
  };

  // バリデーション関数
  const validateForm = (data) => {
    const newErrors = {};
    if (!data.fullname) newErrors.fullname = "氏名は必須項目です。";
    if (
      data.phone &&
      !/^(0[5-9]0[-(]?[0-9]{4}[-)]?[0-9]{4}|0120[-]?\d{1,3}[-]?\d{4}|050[-]?\d{4}[-]?\d{4}|0[1-9][-]?\d{1,4}[-]?\d{1,4}[-]?\d{4})*$/.test(
        data.phone,
      )
    ) {
      newErrors.phone = "正しい形式で入力してください。";
    }
    if (
      data.invoice_register_number &&
      !/^\d{13}$/.test(data.invoice_register_number)
    ) {
      newErrors.invoice_register_number = "13桁の数字を入力してください。";
    }
    if (data.post_number && !/^[0-9]{3}-?[0-9]{4}$/.test(data.post_number)) {
      newErrors.post_number = "7桁の数字で入力してください。";
    }
    if (
      !data.only_address &&
      data.post_number2 &&
      !/^[0-9]{3}-?[0-9]{4}$/.test(data.post_number2)
    ) {
      newErrors.post_number2 = "7桁の数字で入力してください。";
    }
    return newErrors;
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <>
      <TitleBlock title="個人情報" />
      <Link
        to="https://soremo.jp/privacypolicy"
        target="_blank"
        rel="noopener noreferrer"
        className="u-w100 u-mb64"
      >
        <StyledLink>個人情報の管理方法について確認...</StyledLink>
      </Link>

      <div className="u-col u-gap32 u-w100">
        <div className="u-col u-gap16 u-w100">
          <Input
            type="text"
            label="氏名"
            option="[必須]"
            placeholder="山田 太郎"
            name="fullname"
            alert={errors.fullname}
            value={localUserData.fullname}
            onChange={handleChange}
          />
          <Input
            type="date"
            label="生年月日"
            option="[任意]"
            name="dob"
            alert={errors.dob}
            value={localUserData.dob}
            onChange={handleChange}
          />
          <Input
            type="tel"
            label="電話番号"
            option="[任意]"
            name="phone"
            alert={errors.phone}
            value={localUserData.phone}
            onChange={handleChange}
          />
          <Input
            type="text"
            label="適格請求書発行事業者登録番号"
            option="[任意]"
            name="invoice_register_number"
            prepend="T"
            alert={errors.invoice_register_number}
            value={localUserData.invoice_register_number}
            onChange={handleChange}
          />
        </div>
        <div className="u-w100">
          <Tab
            type="tab"
            list={["請求先", "配送先"]}
            activeTab={activeTab}
            onTabChange={setActiveTab}
            className="u-tab-no-gap"
          />
          <div className="u-col u-gap16 u-w100 u-tab-group">
            {activeTab === "請求先" ? (
              <>
                <div className="u-row u-gap16 u-w100">
                  <Input
                    type="text"
                    label="郵便番号"
                    name="post_number"
                    alert={errors.post_number}
                    value={localUserData.post_number}
                    onChange={handleChange}
                  />
                  <Input
                    type="text"
                    label="都道府県"
                    name="province"
                    alert={errors.province}
                    value={localUserData.province}
                    onChange={handleChange}
                  />
                </div>
                <Input
                  type="text"
                  label="市区町村"
                  name="city"
                  alert={errors.city}
                  value={localUserData.city}
                  onChange={handleChange}
                />
                <Input
                  type="text"
                  label="建物名・部屋番号"
                  name="mansion"
                  alert={errors.mansion}
                  value={localUserData.mansion}
                  onChange={handleChange}
                />
              </>
            ) : (
              <>
                <Toggle
                  value={localUserData.only_address}
                  onClick={() =>
                    setLocalUserData((prev) => ({
                      ...prev,
                      only_address: !prev.only_address,
                    }))
                  }
                >
                  請求先と同じ
                </Toggle>
                {!localUserData.only_address && (
                  <>
                    <Input
                      type="text"
                      label="郵便番号"
                      name="post_number2"
                      alert={errors.post_number2}
                      value={localUserData.post_number2}
                      onChange={handleChange}
                    />
                    <Input
                      type="text"
                      label="都道府県"
                      name="province2"
                      alert={errors.province2}
                      value={localUserData.province2}
                      onChange={handleChange}
                    />
                    <Input
                      type="text"
                      label="市区町村"
                      name="city2"
                      alert={errors.city2}
                      value={localUserData.city2}
                      onChange={handleChange}
                    />
                    <Input
                      type="text"
                      label="建物名・部屋番号"
                      name="mansion2"
                      alert={errors.mansion2}
                      value={localUserData.mansion2}
                      onChange={handleChange}
                    />
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {isSaving && <div>保存中...</div>}
      {updateError && <div className="error">{updateError}</div>}
    </>
  );
};

export default PersonalInformation;
